<template>
   <div>
    <vx-card class="mb-4">
      <div class="vx-row w-full">
        <div class="vx-col">
          <b>{{ $t('certified.name') }}</b>
        </div>
      </div>
      <div class="vx-row w-full">
        <div class="vx-col">{{model.name}}</div>
      </div>
      <div class="vx-row w-full">
        <div class="vx-col">
          <e-charts autoresize :options="dataChart" ref="graph"/>
        </div>
      </div>
      <div class="w-full flex gap-2 pt-2 justify-end">
        <vs-button
          type="border"
          @click="goBack"
          icon="undo">
          {{ $t('common.back') }}
        </vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { debounce } from 'lodash'
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/chart/line'


import CertifiedService from '@/services/api/CertifiedService'

export default {
  directives: {

  },
  components: {
    ECharts
  },
  props: {
    id: { //Certified Id
      type: Number,
      default: null
    }
  },
  data: () => ({
    model:{},
    dataChart: {},
    service: null,
  }),
  beforeMount() {
    this.service = CertifiedService.build(this.$vs)
  },
  mounted() {
    if(this.id){
      this.loadData(this.id)
      //this.loadDataChart(this.id)
    }
  },
  beforeDestroy() {

  },
  methods: {
    loadData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        response => {
          this.model = response
        },
        error => {

        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    loadDataChart(id) {
      this.$vs.loading()
      this.service.getCertifiedDataChart(id).then(
        response => {
          this.dataChart = response
        },
        error => {

        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    goBack() {
      this.$router.push({
        name: 'certified_emit_dashboard_index'
      })
    },
  },
  computed: {

  }
}
</script>


<style lang="scss" scoped>

</style>
