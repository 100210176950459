import Rest from '../Rest'

export default class CertifiedService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/certifieds'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getCertifiedDataChart(certifiedId) {
    return this.get(`${certifiedId}/dashboard/data_chart`)
  }

}
